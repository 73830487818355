@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


/* h1 {
  text-shadow: 5px 5px 1px rgba(0, 0, 0, 0.5);
} */
@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-Font/Gotham-Bold.otf') format('truetype');

    font-family: 'Lato';
    src: url('./assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
  }

  body {
    font-family: 'Gotham', sans-serif;
  }
/* .bg-main-mobile{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: url('./assets/images/bg_main_mobile.png');
} */

